<template>
  <div class="cart-services-list">

    <div class="cart-services-list__search">
      <v-text-field
        hide-details
        prepend-inner-icon="mdi-magnify"
        clearable
        outlined
        :value="search"
        @input="onSearch"
        placeholder="Поиск услуг"
      />
    </div>

    <ul class="cart-services-list__items">
      <li v-for="service in filtered" :key="service.uid">
        <Service @select="onServiceSelected" :service="service" />
      </li>
    </ul>

    <v-dialog v-model="showModal" :width="320" @click:outside="closeModal">
      <Details
        v-if="choosenService"
        :service="choosenService"
        @add="onDetailsAdded"
      />
    </v-dialog>

  </div>
</template>

<script>
  // import Fuse from 'fuse.js'
  import Service from '@/components/Cart/Service.vue'
  import Details from '@/components/Cart/Details.vue'
  import debounce from 'lodash/debounce'

  import normalizeServicePrices from '@/utils/normalizeServicePrices'
  import servicesApi from '@/services/service'
  import specialitiesApi from '@/services/specialities'


  export default {
    components: { Service, Details },
    // props: {
    //   services: {
    //     type: Array
    //   }
    // },
    data () {
      return {
        services: [],
        search: null,
        choosenService: null,
        showModal: false,
        specs: [],
      }
    },
    computed: {
      filtered () {
        return this.services
        // if (!this.search) return this.services
        // const s = new Fuse(this.services, Object.freeze({
        //   shouldSort: true,
        //   threshold: 0.25,
        //   location: 0,
        //   distance: 500,
        //   maxPatternLength: 32,
        //   minMatchCharLength: 2,
        //   keys: ["name"]
        // }))

        // return s.search(this.search).map(({ item }) => item)
      }
    },
    watch: {
      search () {
        this.fetchServices()
      }
    },
    created () {
      return this.fetchSpecs()
    },
    methods: {
      async fetchSpecs () {
        const specialities = await specialitiesApi.getAll()
        this.specs = specialities.filter(({ name }) => (/стоматолог/i.test(name))).map(({ id }) => id)
      },
      async fetchServices () {
        const { items } = await servicesApi.getOnlyPrice({
          specialityIds: this.specs,
          limit: 50,
          search: this.search
        })
        this.services = normalizeServicePrices(items)
      },
      onSearch: debounce(function (value) {
        this.search = value
      }, 500),
      onServiceSelected (service) {
        this.showModal = true
        this.choosenService = service
      },
      closeModal () {
        this.showModal = false
        this.choosenService = null
      },
      async onDetailsAdded (service) {
        this.closeModal()
        await this.$store.dispatch('calc/pushService', { ...service })
      }
    }
  }
</script>


<style lang="scss">
  .cart-services-list__search {
    position: sticky;
    top: 45px;
    padding: 0 0 16px;
    background: #fff;
    z-index: 1;
  }
  ul.cart-services-list__items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    & > * {
      padding: 0;
      margin: 0 0 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

</style>
