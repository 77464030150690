import BaseService from './baseService'

class EhrService extends BaseService {
  get url () {
    return 'ehr'
  }
}

export default new EhrService()

