<template>
  <div class="cart-subgroup">
    <div class="cart-subgroup__name" style="opacity: 0.55">
      {{ title }}
    </div>
    <transition-group tag="ul" name="list" class="cart-subgroup__services">
      <li v-for="service in services" :key="service[serviceKeyField]">
        <slot v-bind="service"></slot>
      </li>
    </transition-group>
  </div>
</template>

<script>
  export default {
    props: {
      serviceKeyField: {
        type: String,
        default: 'uid'
      },
      subgroup: {
        type: Object
      }
    },
    computed: {
      title () {
        return this.subgroup.title
      },
      services () {
        return this.subgroup.services
      }
    }
  }
</script>

<style lang="scss">
  .cart-subgroup {
    padding: 2px 4px 2px 16px;
  }

  ul.cart-subgroup__services {
    margin: 0;
    padding: 0;
  }

  .cart-subgroup__name {
  }
</style>
