<template>
  <div class="counter" :class="">
    <v-btn class="counter__btn counter__dec" @click="dec" :disabled="disabled || isSmaller" x-small depressed>
      <v-icon x-small>mdi-minus</v-icon>
    </v-btn>
    <input type="text" class="counter__input" :value="val" disabled>
    <v-btn class="counter__btn counter__inc" @click="inc" :disabled="disabled || isLarger" x-small depressed>
      <v-icon x-small>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      step: {
        type: Number,
        default: 1
      },
      value: {
        type: Number,
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        default: Infinity,
        validator: (prop) => ((prop === Infinity) || (typeof prop === 'number'))
      }
    },
    watch: {
      value (value) {
        this.val = value
      },
    },
    data () {
      return {
        val: typeof this.value === 'number' ? this.value : 0
      }
    },
    methods: {
      inc () {
        if (this.isLarger || this.disabled) return
        this.input(this.val + this.step)
      },
      dec () {
        if (this.isSmaller || this.disabled) return
        this.input(this.val - this.step)
      },
      input (value) {
        if (typeof this.value === 'number') {
          this.$emit('input', value)
        } else {
          this.val = value
        }
      }
    },
    computed: {
      classList () {
        return { 'counter--disabled': this.disabled }
      },
      isLarger () {
        return this.val >= this.max
      },
      isSmaller () {
        return this.val <= this.min
      },
    }
  }
</script>

<style lang="scss">
  $counter-height: 28px;

  .counter {
    position: relative;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
    border: 2px solid #eee;
    height: $counter-height;
  }
  .counter--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .counter__input {
    text-align: center;
    width: 40px;
    height: 100%;
  }

  .counter__btn {
    border-radius: 0;
    width: 24px !important;
    height: 100% !important;
    min-width: 0 !important;
  }

  .counter__dec {
  }
  .counter__inc {
  }
</style>
