<template>
  <div class="cart">

    <div v-if="isCartEmpty" class="cart__empty-message">
      <div>Выберите услуги</div>
    </div>

    <template v-else>
      <CartPersons class="cart__persons" />

      <div class="cart__controls" >
        <v-btn fab small @click="addGroup" title="Добавить группу">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn fab small @click="print" title="Распечатать" :disabled="busy || !patient">
          <v-icon>mdi-printer-pos</v-icon>
        </v-btn>
        <v-btn fab small @click="attach" title="Прикрепить пациенту" :disabled="busy || !patient">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <v-btn fab small @click="clean" title="Очистить">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div style="margin-left: auto; margin-right: 0" v-if="summary.price">
          <span>Итого: </span>
          <Price :value="summary.price/100" style="margin-left: 8px; font-size: 20px;" />
        </div>
      </div>

      <CartServices class="cart__services" />
    </template>

  </div>
</template>

<script>
  import CartServices from '@/components/Cart/CartServices.vue'
  import Price from '@/components/Price/Price.vue'
  import CartPersons from '@/components/Cart/CartPersons.vue'

  export default {
    components: { CartServices, Price, CartPersons },
    methods: {
      addGroup () {
        this.$store.dispatch('calc/pushGroup')
      },
      async print () {
        try {
          await this.$store.dispatch('calc/print')
        } catch (e) {
          console.error(e)
        }
      },
      async attach () {
        if (!confirm(`Документ будет прикреплен к медкарте пациента ${this.patient.fullName} (${this.patient.recordId})`)) {
          return
        }
        try {
          await this.$store.dispatch('calc/attachToAccount')
          alert('Готово! Документ прикреплен')
        } catch (e) {
          alert('Не удалось прикрепить')
          console.error(e)
        }
      },
      clean () {
        if (!confirm('Все добавленные услуги будут удалены. Продолжить?')) return
        this.$store.dispatch('calc/clean')
      },
    },
    computed: {
      busy () {
        return this.$store.state.calc.busy
      },
      summary () {
        return this.$store.getters['calc/cart'].summary
      },
      patient () {
        return this.$store.state.calc.patient
      },
      isCartEmpty () {
        return !this.$store.getters['calc/cart'].groups.length
      }
    }
  }
</script>

<style lang="scss">
  .cart {
    display: flex;
    flex-direction: column;
  }

  .cart__controls {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > * {
      margin-left: 16px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .cart__services {
    overflow-y: auto;
    flex: 1 1 auto;
  }

  .cart__empty-message {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
  }

  .cart__persons {
    margin-bottom: 20px;
  }
</style>
