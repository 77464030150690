<script>
  export default {
    functional: true,
    props: {
      minRange: {
        type: [String, Number],
        default: 10000
      },
      value: {
        type: [Number, String]
      }
    },
    render (h, { props, data, children }) {
      const divider = ' '
      const value = props.value || (children ? children[0].text : null)
      if (!value) return null
      const format = (string) => (string.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${divider}`))
      const stringValue = String(value)
      const numberValue = Number(value)
      const content = numberValue >= Number(props.minRange) ? format(stringValue) : stringValue

      const valueEl = h('span', { staticClass: 'price__value'}, content)
      const currencyEl = h('span', { staticClass: 'price__currency'}, '₽')

      return h('span', { ...data, class: { 'price': true } }, [valueEl, currencyEl])
    }
  }
</script>

<style lang="scss">
  .price {
    display: inline-flex;
    align-items: baseline;
  }

  .price__value {
    white-space: nowrap;
  }

  .price__currency {
    opacity: 0.8;
    margin-left: 0.25em;
  }
</style>
