<template>
  <transition-group tag="ul" name="list" class="cart-services">
    <li
      v-for="group in groups"
      :key="group.uid"
      :class="group.isActive ? 'cart-services__group--active' : ''"
      class="cart-services__group"
    >
      <CartGroupHead :autofocus="reactOnUpdates" :group="group" @click="setGroup(group)" />
      <!--<div class="cart-services__a" style="padding: 16px 16px 40px;"  v-if="group.isActive && !group.services.length">-->
        <!--Добавьте услуги-->
      <!--</div>-->

      <transition-group
        tag="ul"
        name="list"
        class="cart-services__items"
        v-show="group.subgroups.length && group.isActive"
      >
        <li v-for="subgroup in group.subgroups" :key="subgroup.title">
          <Subgroup :subgroup="subgroup" v-slot="service">
            <CartServiceItem :highlight-on-mount="reactOnUpdates" :service="service" />
          </Subgroup>
        </li>
      </transition-group>

    </li>
  </transition-group>
</template>

<script>
  import Subgroup from '@/components/Cart/CartSubgroup.vue'
  import CartGroupHead from '@/components/Cart/CartGroupHead.vue'
  import CartServiceItem from '@/components/Cart/CartServiceItem.vue'

  export default {
    components: { CartGroupHead, CartServiceItem, Subgroup },
    data () {
      return {
        reactOnUpdates: false,
      }
    },
    mounted () {
      setTimeout(() => (this.reactOnUpdates = true), 500)
    },
    methods: {
      async setGroup (group) {
        await this.$store.dispatch('calc/openGroup', group.uid)
      }
    },
    computed: {
      groups () {
        const groups = this.$store.getters[`calc/cart`].groups.slice().reverse()
        const by = 'tooth'
        const details = this.$store.state.calc.config.detailsTemplate.find(({ name }) => (name === by))
        const label = details.label

        function groupBy (keyField, services) {
          const UNGROUPED = '_'
          const groups = services.reduce((acc, service) => {
            const keyName = service.details[keyField] || UNGROUPED
            const title = keyName !== UNGROUPED ? `${label}: ${keyName}` : ''
            acc[keyName] = acc[keyName] || { title, services: [] }
            acc[keyName].services.push(service)
            return acc
          }, {})
          return Object.values(groups).sort((a, b) => (!a.title ? -1 : 0))
        }

        const sections = groups.map((group) => ({ ...group, subgroups: groupBy(by, group.services) }))
        return sections
      }
    }
  }
</script>

<style lang="scss">
  ul.cart-services {
    list-style: none;
    margin: 0;
    padding: 0;
    & > * {
      margin: 0;
      padding: 0;
    }
  }

  ul.cart-services__items {
    list-style: none;
    margin: 0;
    padding: 0;
    & > * {
      margin: 0 0 12px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cart-services__group {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .cart-services__group--active {
    background: #f7f7f7;
  }


  ul.cart-services__items {
    padding: 8px 0 8px;
  }
</style>
