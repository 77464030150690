<template>
  <div class="cart-persons">
    <div>
      <cart-person
        :person="doctor"
        @reset="() => input('doctor', null)"
      >
        <template #person>
          <span style="opacity: 0.5">
            Доктор:
          </span>
          {{ doctor.fullName }}
        </template>
        <template #control>
          <SelectDoctor
            label="Доктор"
            outlined
            hide-details="auto"
            @input="input('doctor', $event)"
          />
        </template>
      </cart-person>
    </div>

    <cart-person
      :person="patient"
      @reset="() => input('patient', null)"
    >
      <template #person>
        <span style="opacity: 0.5">Пациент: </span>
        {{ patient.fullName}} ({{ patient.recordId }})
      </template>
      <template #control>
        <SelectPatient
          label="Пациент"
          outlined
          hide-details="auto"
          v-model="patient"
          @input="input('patient', $event)"
        />
      </template>
    </cart-person>
  </div>
</template>

<script>
import CartPerson from './CartPerson.vue'
import { createNamespacedHelpers } from 'vuex'
import SelectDoctor from './SelectDoctor.vue'
import SelectPatient from './SelectPatient.vue'
const { mapMutations, mapState } = createNamespacedHelpers('calc')

export default {
  components: {
    CartPerson,
    SelectDoctor,
    SelectPatient
  },
  computed: {
    ...mapState([
      'patient',
      'doctor',
    ])
  },
  methods: {
    ...mapMutations([
      'set'
    ]),
    input (field, item) {
      this.set({ [field]: item })
    },
  }
}
</script>

<style lang="scss" scoped>
.cart-persons {
  & > * {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
