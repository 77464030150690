<template>
  <form @submit.prevent="submit">
    <h3 class="text-h4">
      Добавление услуги
    </h3>

    <div style="margin: 8px 0">{{ service.name }}</div>

    <v-text-field autofocus v-model="details.tooth" placeholder="Укажите номер зуба"></v-text-field>
    <v-btn color="success" type="submit">Готово</v-btn>
  </form>
</template>

<script>
  export default {
    props: {
      service: {
        type: Object
      },
    },
    data () {
      return {
        details: {
          tooth: null,
          comment: null
        }
      }
    },
    methods: {
      submit () {
        this.$emit('add', { ...this.service, details: this.details })
      }
    }
  }
</script>
