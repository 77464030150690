import { v4 as uuid } from 'uuid'

export default function (items) {
  return items.reduce((acc, item) => {
    const subservices = item.prices.reduce((acc, priceItem) => {
      if (priceItem.value) {
        const key = `${priceItem.value}`
        if (!acc[key]) {
          acc[key] = {
            uid: uuid(),
            ...item.service,
            price: priceItem.value,
            clinics: {},
            doctors: {}
          }
        }

        if (priceItem.doctor) acc[key].doctors[priceItem.doctor.id] = priceItem.doctor
        if (priceItem.clinic) acc[key].clinics[priceItem.clinic.id] = priceItem.clinic
      }
      return acc
    }, {})
    acc = acc.concat(Object.values(subservices))
    return acc
  }, [])
}
