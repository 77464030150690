<template>
  <div class="cart-app">

    <div v-if="fetching" class="cart-app__preloader text-h3">Загрузка услуг...</div>

    <template v-else>
      <!-- <Services class="cart-app__services" :services="services" /> -->
      <Services class="cart-app__services" />

      <div class="cart-app__aside">
        <Cart class="cart-app__cart" />
      </div>
    </template>

  </div>
</template>

<script>
  // import normalizeServicePrices from '@/utils/normalizeServicePrices'
  // import servicesApi from '@/services/service'
  // import specialitiesApi from '@/services/specialities'
  import Services from '@/components/Cart/Services.vue'
  import Cart from '@/components/Cart/Cart.vue'
  import { configTypeNames } from '@/store/modules/calc'

  const shouldSaveState = false

  export default {
    components: { Cart, Services },
    data () {
      return {
        fetching: false,
        // services: [],
        unsyncLocalStorage: null
      }
    },
    async mounted () {
      const author = this.$store.getters['currentUser']
      let storedState
      this.fetching = true
      try {
        // this.services = await this.fetchServices()
        if (shouldSaveState) {
          storedState = await this.loadState()
          this.syncLocalStorage()
        }
        await this.$store.dispatch('calc/init', { author, type: configTypeNames.DENTIST, state: storedState || null })
      } catch (e) {
        alert('Ошибка! Не удалось загрузить компонент')
        console.error(e)
      }
      this.fetching = false
    },
    beforeDestroy () {
      if (shouldSaveState) {
        this.unsyncLocalStorage()
      }
      this.$store.commit('calc/reset')
    },
    methods: {
      syncLocalStorage () {
        this.unsyncLocalStorage = this.$store.subscribe((mutation, state) => {
          const json = JSON.stringify(state.calc)
          localStorage.setItem('calc', json)
        })
      },
      async loadState () {
        return JSON.parse(localStorage.getItem('calc'))
      },
      // async fetchServices () {
      //   console.log('fetching')
      //   const specialities = await specialitiesApi.getAll()
      //   const dentistSpecs = specialities.filter(({ name }) => (/стоматолог/i.test(name))).map(({ id }) => id)
      //   const servicesList = await servicesApi.getOnlyPrice({ specialityIds: dentistSpecs, limit: 3 })
      //   return normalizeServicePrices(servicesList.items)
      // }
    }
  }
</script>


<style lang="scss">
  .cart-app {
    display: flex;
  }

  .cart-app__services {
    flex: 1 1 auto
  }

  .cart-app__aside {
    margin-left: 20px;
    flex: 0 0 520px;
  }

  .cart-app__cart {
    position: sticky;
    top: 60px;
    height: calc(100vh - 60px);
  }
  .cart-app__preloader {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
