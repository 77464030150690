<template>
  <AutocompleteAsync
    v-on="$listeners"
    v-bind="attrs"
  />
</template>

<script>
import doctors from '@/services/doctors'
import AutocompleteAsync from './AutocompleteAsync.vue'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        name: "doctor",
        placeholder:'Выберите доктора',
        itemText: 'fullName',
        itemValue: 'id',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    load: async (params) => {
      const { items } = await doctors.getAll(params)
      return items
    }
  }
}
</script>
