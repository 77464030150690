<template>
  <div class="cart-person">
    <div class="cart-person__person" v-if="person">
      <div class="cart-person__person-name">
        <slot name="person" v-bind="{ person }"></slot>
      </div>
      <div
        class="cart-person__actions"
        @click="() => $emit('reset')"
      >
        <v-btn icon small><v-icon small>mdi-close</v-icon></v-btn>
      </div>
    </div>
    <slot name="control" v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-person__person {
  display: flex;
  justify-content: space-between;
  grid-gap: 1em;
  align-items: center;
  min-height: 55px;
}
</style>
