<template>
  <div
    class="cart-services-group-head"
    :class="{
      'cart-services-group-head--empty': !group.services.length,
      'cart-services-group-head--active': group.isActive
    }"
    @click="$emit('click')"
  >
    <div class="cart-services-group-head__title">
      <div class="cart-services-group-head__name text-h5">
        <!--<v-icon style="margin-right: 4px" :style="{ visibility: !canRemoveGroup ? 'hidden' : '' }">{{ group.isActive ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>-->

        <v-btn
          fab
          depressed
          x-small
          :style="{ visibility: !editable ? '' : 'hidden' }"
          @click="edit"
          type="button"
          color="transparent"
          title="Переименовать"
        >
          <v-icon small>{{ editable ? 'mdi-close' : 'mdi-pencil' }}</v-icon>
        </v-btn>

        <input
          ref="input"
          :disabled="!editable"
          type="text"
          @keydown.enter="onEnter"
          @keydown.esc="onEsc"
          @blur="edit"
          :value="group.name"
          @input="updateGroupName($event.target.value)"
          class="cart-services-group-head-name-input"
        />
      </div>

      <div class="cart-services-group-head__summary">
        <template v-if="group.services.length">
          <span class="font-weight-bold">{{ group.summary.amount }}</span> услуг на <Price :value="group.summary.price/100" class="font-weight-bold" />
        </template>
        <div v-else>пусто</div>
      </div>
    </div>
    <div class="cart-services-group-head__controls">
      <v-btn color="transparent" depressed fab x-small v-if="canRemoveGroup" @click.stop="removeGroup(group)" title="Удалить">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import Price from '@/components/Price/Price.vue'

  export default {
    components: { Price },
    props: {
      autofocus: {
        type: Boolean,
        default: false
      },
      group: {
        type: Object
      }
    },
    data () {
      return {
        editable: false,
        prevname: null
      }
    },
    mounted () {
      if (this.autofocus) this.edit()
    },
    computed: {
      canRemoveGroup () {
        return this.$store.getters['calc/canRemoveGroups']
      }
    },
    methods: {
      removeGroup (group) {
        if (confirm('Really?')) {
          this.$store.dispatch('calc/removeGroup', group.uid)
        }
      },
      updateGroupName (name) {
        this.$store.dispatch('calc/updateGroup', { ...this.group, name })
      },
      edit () {
        this.editable = !this.editable
        if (this.editable) {
          this.$nextTick(() => {
            this.$refs.input.focus()
            this.$refs.input.select()
            this.prevname = this.group.name
          })
        } else {
          this.prevname = null
        }
      },
      onEnter () {
        this.$refs.input.blur()
      },
      onEsc () {
        this.updateGroupName(this.prevname)
        this.$refs.input.blur()
      }
    }
  }
</script>

<style lang="scss">
  .cart-services-group-head {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 8px 8px 8px 0;
    border-bottom: 1px solid #e4e4e4;
    .dentist-calc-cart-services__group--active &,
    &:hover {
      color: #ff6c6c;
    }
  }

  .cart-services-group-head--empty {
    color: #a4a4a4
  }

  .cart-services-group-head--active {
    color: #ff6c6c;
  }

  .cart-services-group-head__title {
    flex: 1 1 auto
  }

  .cart-services-group-head__name {
    display: flex;
    align-items: center;
  }

  .cart-services-group-head__summary {
    font-size: 14px;
    padding-left: 32px;
    color: #727272
  }

  .cart-services-group-head__controls {
    flex: 0 0 auto;
    margin: 0 0 0 auto;
    padding-left: 16px;
  }

  .cart-services-group-head-name-input {
    color: inherit;
    font-size: inherit;
    outline: none;
    width: 100%;
    padding: 0;
    border-radius: 4px;
    &:focus {
      background: #fff;
      padding: 8px 4px;
    }
    &:disabled {
      cursor: inherit;
    }
  }
</style>
