<template>
  <AutocompleteAsync
    v-on="$listeners"
    v-bind="attrs"
  >
    <template v-slot:item="{ item }">
      <div style="width: 100%; display: flex; align-items: center; justify-content: space-between">
        <span>{{ item.fullName }}</span>
        <span style="opacity: 0.7; margin-left: 20px">{{ item.recordId }}</span>
      </div>
    </template>
  </AutocompleteAsync>
</template>

<script>
import ehrs from '@/services/ehrs'
import AutocompleteAsync from './AutocompleteAsync.vue'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        name: "patient",
        placeholder:'Выберите пациента',
        itemText: 'searchKey',
        itemValue: 'id',
        load: (...args) => this.load(...args)
      }
    }
  },
  methods: {
    load: async (params) => {
      const items = await ehrs.getAll(params)
      return items.map((item) => {
        const fullName =  [item.lastName, item.firstName, item.patronymic]
          .filter((item) => item)
          .map((item) => item.trim())
          .join(' ')
        return {
         ...item,
          fullName: fullName,
          searchKey: [fullName, item.recordId].join(' ')
        }
      })
    }
  }
}
</script>
