<template>
  <div class="cart-services-item" :class="classList">
    <div class="cart-services-item__name">
      <div class="cart-services-item__name-text">
        {{ service.name }}
      </div>
      <!--<div class="cart-services-item__name-details">-->
        <!--{{ service.details.tooth }}-->
      <!--</div>-->
    </div>

    <div class="cart-services-item__price">
      <Price style="opacity: 0.55" :value="service.price/100" v-if="service.amount > 1" />
    </div>
    <div class="cart-services-item__amount">
      <CounterLight style="opacity: 0.55" :min="1" :value="service.amount" @input="updateAmount" />
    </div>
    <div class="cart-services-item__sum">
      <Price class="font-weight-bold" :value="service.price/100 * service.amount" />
    </div>
    <div class="cart-services-item__controls">
      <v-btn depressed fab x-small color="transparent" @click="removeService(service)" title="Удалить">
        <v-icon color="#b7b7b7" small>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CounterLight from '@/components/elements/counter/CounterLight.vue'
  import Price from '@/components/Price/Price.vue'
  import Counter from '@/components/elements/counter/Counter.vue'

  export default {
    components: { Price, Counter, CounterLight },
    props: {
      highlightOnMount: {
        type: Boolean,
        default: false
      },
      highlight: {
        type: Boolean,
        default: false
      },
      service: {
        type: Object
      }
    },
    data () {
      return {
        blinking: false
      }
    },
    mounted () {
      this.highlightOnMount && this.$nextTick(() => this.blink())
    },
    watch: {
      service (service, oldService) {
        if (service.amount === oldService.amount) return
        this.blink()
      }
    },
    computed: {
      classList () {
        return {
          'cart-services-item--highlight': this.blinking || this.highlight
        }
      }
    },
    methods: {
      removeService (service) {
        this.$store.dispatch('calc/removeService', service.uid)
      },
      updateAmount (amount) {
        this.$store.dispatch('calc/updateService', { ...this.service, amount })
      },
      blink () {
        this.blinking = true
        setTimeout(() => (this.blinking = false), 100)
      }
    }
  }
</script>

<style lang="scss">
  .cart-services-item {
    display: flex;
    align-items: center;
    transition: 1.5s background;

    &:hover {
      transition: 0.5s background;
      background: rgba(#000, 0.05);
    }
  }

  .cart-services-item--highlight {
    background: rgba(#10e7ff, 0.2) !important;
    transition-duration: 0s;
  }

  .cart-services-item__name {
    flex: 1 1 auto;
    padding-right: 20px;
  }

  .cart-services-item__sum {
    padding-left: 8px;
    min-width: 70px;
    text-align: right;
  }

  .cart-services-item__price {
    margin-right: 4px;
  }

  .cart-services-item__controls {
    flex: 0 0 auto;
    margin: 0 0 0 auto;
    padding-left: 8px
  }

  .cart-services-item__amount {
  }


  .cart-services-item__name-details {
    font-size: 12px;
    opacity: 0.7;
  }
</style>
