<template>
  <div class="counter-light" :class="classes">
    <div class="counter-light__value">
      <span class="counter-light__value-times">&times;</span>
      <input type="text" class="counter-light__input" :disabled="disabled" @keydown="onKeydown" :value="val" />
    </div>

    <div class="counter-light__buttons">
      <button type="button" class="counter-light__dec counter-light__btn" @click="dec" :disabled="isSmaller || disabled">
        <v-icon x-small style="color: inherit">mdi-minus</v-icon>
      </button>
      <button type="button" class="counter-light__inc counter-light__btn" @click="inc" :disabled="isLarger || disabled">
        <v-icon x-small style="color: inherit">mdi-plus</v-icon>
      </button>
    </div>

  </div>
</template>

<script>
  import Counter from '@/components/elements/counter/Counter.vue'

  export default {
    extends: Counter,
    props: {
      vertical: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes () {
        return {
          ...this.classList,
          'counter-light--horizontal': !this.vertical,
          'counter-light--vertical': this.vertical
        }
      }
    },
    methods: {
      onKeydown (e) {
        if (e.key === 'ArrowUp') {
          this.inc()
        } else if (e.key === 'ArrowDown') {
          this.dec()
        } else if (e.key === 'Tab') {
          // do nothing
        } else {
          e.preventDefault()
        }
      }
    }
  }
</script>

<style lang="scss">
  .counter-light {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .counter--disabled {
    opacity: 0.5;
  }

  .counter-light--horizontal {
    flex-direction: row;
  }

  .counter-light--vertical {
    flex-direction: column;
  }

  .counter-light__buttons {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    visibility: hidden;

    .counter-light:hover & {
      visibility: visible;
    }

    .counter-light--horizontal & {
      left: -20px;
      right: -20px;
      top: -10px;
      bottom: -10px;
      flex-direction: row;
      transform: translateX(-5px)
    }
    .counter-light--vertical & {
      left: -10px;
      right: -10px;
      top: -20px;
      bottom: -20px;
      flex-direction: column-reverse;
    }
  }

  .counter-light__btn {
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #c5c5c5;
    outline: none;

    &:hover, &:focus {
      background: #2c2c2c;
      color: #fff
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .counter-light__value {
    position: relative;
    display: flex;
    align-items: center;
    .counter-light--horizontal & {
      padding: 4px;
    }
    z-index: 1;
  }

  .counter-light__value-times {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-51%)
  }

  .counter-light__input {
    outline: none;
    min-width: 20px;
    max-width: 30px;
    border-radius: 2px;
    text-align: center;
    padding: 4px;
    &:focus {
      background: #fff;
    }
  }
</style>
