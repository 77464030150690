<template>
  <div class="cart-service">
    <div class="cart-service__main">
      <div class="cart-service__head">
        <div class="cart-service__name text-h5">
          {{ service.name }}
        </div>

        <div class="cart-service__status" v-if="clinics.length">
          <v-btn @click="toggleClinics" fab x-small depressed color="transparent" title="Показать клиники">
            <v-icon small color="#7d7b7b">mdi-map-marker</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="cart-service__meta text-body-1">
        <div class="cart-service__clinics" v-if="clinics.length && isClinicsVisible">
          <v-icon small>mdi-map-marker</v-icon>
          <span>{{ clinicsNames }}</span>
        </div>
        <div class="cart-service__doctors" v-if="doctors.length">
          <v-icon small>mdi-account</v-icon>
          <div class="cart-service__doctors-short" v-if="isDoctorsShort">
            <span>{{ doctorsShort.list }}</span>
            <span style="white-space: nowrap" v-if="doctorsShort.others">
              и <a href="#" @click.prevent="toggleDoctors">еще {{ doctorsShort.others }}</a>
            </span>
          </div>
          <div class="cart-service__doctors-full" v-else>
            <div class="cart-service__doctors-list">
              <div v-for="doctor in doctors" :key="doctor.id">{{ doctor.fullName }}</div>
            </div>
            <div style="margin-top: 8px">
              <a href="#" @click.prevent="toggleDoctors">Свернуть</a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="cart-service__aside">
      <div class="cart-service__price" style="white-space: nowrap">
        <Price :value="service.price/100" />
      </div>
      <v-btn class="cart-service__add" color="success" @click="$emit('select', service)">Добавить</v-btn>
    </div>
  </div>
</template>

<script>
  import Price from '@/components/Price/Price.vue'

  export default {
    components: { Price },
    props: {
      service: { type: Object }
    },
    data () {
      return {
        isDoctorsShort: true,
        isClinicsVisible: false
      }
    },
    methods: {
      toggleDoctors () {
        this.isDoctorsShort = !this.isDoctorsShort
      },
      toggleClinics () {
        this.isClinicsVisible = !this.isClinicsVisible
      }
    },
    computed: {
      clinics () {
        return Object.values(this.service.clinics)
      },
      clinicsNames ()  {
        return this.clinics.map(({ shortName }) => shortName).join(', ')
      },
      doctors () {
        return Object.values(this.service.doctors)
      },
      doctorsShort () {
        const limit = 2
        let shortList = this.doctors.slice(0, limit)
        const others = this.doctors.length - shortList.length
        shortList = shortList.map(({ fullName }) => fullName).join(', ')
        return { list: shortList, others }
      }
    }
  }
</script>

<style lang="scss">
  .cart-service {
    border: 1px solid #eee;
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: #f7f7f7;
    }
  }

  .cart-service__status {
    margin-left: 8px;
    & > * {
      vertical-align: baseline;
    }
  }

  .cart-service__head {
    display: inline-flex;
    align-items: baseline;
    & > * {
      flex: 1 1 auto;
    }
  }

  .cart-service__meta {
    opacity: 0.8;
  }

  .cart-service__aside {
    margin: 0 0 0 auto;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .cart-service__add {
    margin-left: 16px;
  }

  .cart-service__clinics {
    .mdi {
      margin-right: 8px;
    }
  }

  .cart-service__doctors {
    display: flex;
    align-items: baseline;
    margin-top: 4px;
    .mdi {
      margin-right: 8px;
    }
  }

  .cart-service__doctors-full {
    margin-top: 8px;
  }

  .cart-service__doctors-list {
    flex: 0 1 auto;
    @media screen and (min-width: 768px) {
      columns: 2;
      column-gap: 32px;
      & > * {
        break-inside: avoid;
        margin-bottom: 12px;
        line-height: 1.25em;
      }
    }
  }
</style>
